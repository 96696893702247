import { accountServiceClient } from './base';
import { decodePlans } from '~/schema/account';

export const createAccount = (account) =>
  accountServiceClient.post('', account);

export const getAccount = () => accountServiceClient.get('');

export const updateAccount = (account) => accountServiceClient.put('', account);

export const getBillingHistory = () =>
  accountServiceClient.get('/billing-history');

export const getSubscription = () => accountServiceClient.get('/subscription');

export const changeSubscription = (subscription) =>
  accountServiceClient.post('/subscription/change', subscription);

export const cancelSubscription = () =>
  accountServiceClient.post('/subscription/cancel');

export const cancelSubscriptionSchedule = () =>
  accountServiceClient.post('/subscription/schedule/cancel');

export const getPlans = async () => {
  const { data = [] } = await accountServiceClient.get('/subscription/plans');
  return { data: decodePlans(data) };
};

export const getPaymentMethods = () =>
  accountServiceClient.get('/payment-method/list');

export const addPaymentMethod = () =>
  accountServiceClient.post('/payment-method');

export const deletePaymentMethod = (id) =>
  accountServiceClient.delete(`/payment-method`, { data: { id } });

export const setDefaultPaymentMethod = (id) =>
  accountServiceClient.post(`/payment-method/default`, { id });

export const getQuote = async (subscription) =>
  accountServiceClient.post('/subscription/quote', subscription);

export const getCoupon = async ({ code, active }) =>
  accountServiceClient.get(`/coupons/${encodeURIComponent(code)}`, {
    params: { active },
  });
