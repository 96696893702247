import * as apiClient from '~/api-client';
import { reader, writer } from '~/helpers/action';
import { updateAccount, updatePlans, getStates } from '~/store';
import { makeBlankAccount } from '~/schema/account';

import delay from 'delay';
import { refreshAuth } from './auth';

export const getSubscription = reader('subscription', async (store = true) => {
  const { data: subscription } = await apiClient.getSubscription();
  if (store) {
    const { account } = getStates();
    updateAccount({ ...account, subscription });
  }
  return subscription;
});

export const subscribe = writer(
  'subscription',
  async (subscription, store = true) => {
    const { data } = await apiClient.changeSubscription(subscription);
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, subscription: data });
    }
    return data;
  },
  {
    onSuccess: '',
    operation: 'change',
  }
);

export const cancelSubscription = writer(
  'subscription',
  async (store = true) => {
    const { data: subscription } = await apiClient.cancelSubscription();
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, subscription });
    }
    return subscription;
  },
  {
    onSuccess: '',
    operation: 'cancel',
  }
);

export const getPlans = reader('plans', async (store = true) => {
  const { data } = await apiClient.getPlans();
  if (store) updatePlans(data);
  return data;
});

export const cancelSubscriptionSchedule = writer(
  'subscription schedule',
  async (store = true) => {
    const { data: subscription } = await apiClient.cancelSubscriptionSchedule();
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, subscription });
    }
    return subscription;
  },
  {
    onSuccess: '',
    operation: 'cancel',
  }
);

export const addPaymentMethod = reader(
  'payment method',
  async (param) => {
    const { data } = await apiClient.addPaymentMethod(param);
    return data;
  },
  { operation: 'add' }
);

export const getPaymentMethods = reader(
  'payment methods',
  async (store = true) => {
    const { data: paymentMethods = [] } = await apiClient.getPaymentMethods();
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, paymentMethods });
    }
    return paymentMethods;
  }
);

export const deletePaymentMethod = writer(
  'payment method',
  async (id, store = true) => {
    const { data: paymentMethods = [] } = await apiClient.deletePaymentMethod(
      id
    );
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, paymentMethods });
    }
    return paymentMethods;
  },
  {
    onSuccess: '',
    operation: 'delete',
  }
);

export const setDefaultPaymentMethod = writer(
  'payment method',
  async (id, store = true) => {
    const { data: paymentMethods = [] } =
      await apiClient.setDefaultPaymentMethod(id);
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, paymentMethods });
    }
    return paymentMethods;
  },
  {
    onSuccess: '',
    operation: 'update',
  }
);

export const getBillingHistory = reader('billing history', async () => {
  const { data = [] } = await apiClient.getBillingHistory();
  return data;
});

export const create = writer('account', async (account) => {
  const {
    auth: { accessToken },
  } = getStates();
  const { data = makeBlankAccount() } = await apiClient.createAccount(account);

  // if user was already logged in, user's access token needs
  // to be updated to reflect account ownership
  if (accessToken) {
    // wait a bit as account GSI takes a bit of time to reflect
    await delay(1000);
    await refreshAuth();
  }

  return data;
});

export const get = reader(
  'account',
  async (store = true) => {
    const { data = makeBlankAccount() } = await apiClient.getAccount();
    if (store) {
      const { account } = getStates();
      updateAccount({ ...account, ...data });
    }
    return data;
  },
  { onFailure: '' }
);

export const update = writer('account', async (account, store = true) => {
  const { data = makeBlankAccount() } = await apiClient.updateAccount(account);
  if (store) {
    const { account: oldAccountData } = getStates();
    updateAccount({ ...oldAccountData, ...data });
  }
  return data;
});

export const getQuote = reader(
  'subscription',
  async (subscription) => {
    const { data } = await apiClient.getQuote(subscription);
    return data;
  },
  { operation: 'quote' }
);

export const getCoupon = reader('coupon', async (...args) => {
  const { data } = await apiClient.getCoupon(...args);
  return data;
});
